import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faFolderPlus, faFilePlus } from '@fortawesome/pro-solid-svg-icons';
import { DocumentTreeNode } from '@shared/models/documents.model';
import { PropertyDocument } from '@shared/models/properties.model';
import { DocumentService } from '@shared/services/document.service';
import { RootType, RootTypeConfigs } from './root-type-configs';

interface ChangeOperation {
	oldPath: string;
	newPath: string;
}

@Component({
	templateUrl: './document-explorer.component.html',
	selector: 'app-document-explorer',
	styleUrls: ['./document-explorer.component.scss']
}) export class DocumentExplorerComponent implements OnInit {
	documentTree: DocumentTreeNode;
	@ViewChild('fileInput') fileInput: ElementRef;
	@Output() refresh = new EventEmitter();
	@Input() rootId: string;
	@Input() rootType: RootType;
	@Input() columns = 2;
	@Input() initialPath: number[] = [];
	@Input() canWrite: boolean;
	@Input() set documents(documents: PropertyDocument[]) {
		this.documentTree = this.sortByFolderThenName(
			this.buildDocumentTree(documents.slice())
		);
	}
	addFolderIcon = faFolderPlus;
	addFileIcon = faFilePlus;
	maxCharLength = 50;
	fileToMove: DocumentTreeNode | undefined;
	completedOperations = 0;
	totalOperations = 0;
	isProgressVisible = false;

	private _curPath: number[];

	get curNode(): DocumentTreeNode {
		let currentNode = this.documentTree;
		for (const index of this._curPath) {
			currentNode = currentNode.children[index];
		}

		return currentNode;
	}

	get isRoot(): boolean {
		return this._curPath.length === 0;
	}

	get breadcrumbs(): { name: string; path: number[] }[] {
		let node = this.documentTree;
		const breadcrumbs: {
			name: string;
			path: number[]
		}[] = [
			{
				name: 'Root',
				path: []
			}
		];

		for (let i = 0; i < this._curPath.length; i++) {
			const index = this._curPath[i];
			node = node.children[index];
			breadcrumbs.push({
				name: node.name,
				path: this._curPath.slice(0, i + 1)
			});
		}

		return breadcrumbs;
	}

	get fileMoveAvailable(): boolean {
		return this.curNode.children?.some(c => c.isFolder) || !!this.curNode.name.length;
	}

	get curNodeFolders(): DocumentTreeNode[] {
		return this.curNode.children?.filter(c => c.isFolder);
	}

	trackByFn = (item) => {
		return item.name;
	}

	constructor(private docs: DocumentService) { }

	ngOnInit(): void {
		this.setPath(this.initialPath);
	}

	// builds a document tree from the provided documents array
	buildDocumentTree(documents: PropertyDocument[]): DocumentTreeNode {
		const root: DocumentTreeNode = {
			name: '',
			isFolder: true,
			url: null,
			fileSize: 0,
			children: []
		};

		for (const doc of documents) {
			// get offset to find 'root' folder
			const path = doc.key.split('/').slice(RootTypeConfigs[this.rootType].offset);
			let node = root;

			for (const name of path) {
				if (name === '') {
					continue; // skip empty path elements
				}

				const child = node.children?.find(c => c.name === name);

				if (child) {
					node = child;
				} else {
					const isFolder = name !== path.at(path.length - 1);
					const newChild: DocumentTreeNode = {
						name,
						url: isFolder ? null : doc.url,
						isFolder,
						fileSize: doc.fileSize,
						children: []
					};
					node.children.push(newChild);
					node = newChild;
				}
			}
		}

		// Function to sort the children of each node
		// folders first, then files. Alphabetical within each group
		const sortChildren = (node: DocumentTreeNode) => {
			if (node.children && node.children.length > 0) {
				node.children.sort((a, b) => {
					if (a.isFolder === b.isFolder) {
						return a.name.localeCompare(b.name);
					}
					return a.isFolder ? -1 : 1;
				});
				node.children.forEach(sortChildren);
			}
		};

		sortChildren(root);

		return root;
	}


	navToChild(index: number) {
		this._curPath.push(index);
	}

	navToParent() {
		this._curPath.pop();
	}

	setPath(path: number[]) {
		this._curPath = path;
	}

	getCurrentPathString(): string {
		let path = '';
		let currentNode = this.documentTree;
		for (const index of this._curPath) {
			if (currentNode.children[index]) {
				currentNode = currentNode.children[index];
				path += currentNode.name + '/';
			} else {
				throw new Error('Invalid path index: ' + index);
			}
		}
		return path;
	}

	addFolder() {
		const folderName = prompt('What do you want to name the new folder?');
		if (folderName) {
			this.docs.createFolder(
				this.rootId, folderName, this.getCurrentPathString(), this.rootType)
				.then(() => this.refresh.emit(this._curPath));
		}
	}

	openFileDialog(): void {
		this.fileInput.nativeElement.click();
	}

	onFilesChosen(event: any): void {
		if (event.target.files) {
			this.uploadFiles(event.target.files);
		}
	}

	uploadFiles(fileList: File[]): void {
		const uploadPromises: Promise<any>[] = [];

		for (const file of fileList) {
			if (file) {
				const uploadPromise = this.docs.uploadFile(
					this.rootId, file, this.getCurrentPathString(), this.rootType);
				uploadPromises.push(uploadPromise);
			}
		}

		Promise.all(uploadPromises)
			.then(() => {
				this.refresh.emit(this._curPath);
			})
			.catch((error) => {
				console.error('An error occurred during file upload:', error);
				window.alert('Error: File Upload Unsuccessful');
			});
	}

	generateChangeList(node: DocumentTreeNode, basePath: string, newName?: string): ChangeOperation[] {
		const operations: ChangeOperation[] = [];

		// to reuse logic for trailing '/' on folders
		const buildPath = (basePath: string, name: string, isFolder: boolean) => {
			return `${basePath}${name}${isFolder ? '/' : ''}`;
		};

		const oldBasePath = buildPath(basePath, node.name, node.isFolder);
		const newBasePath = newName ? buildPath(basePath, newName, node.isFolder) : oldBasePath; // to reuse same code while deleting

		operations.push({ oldPath: oldBasePath, newPath: newBasePath });

		// recursion has to be in scope to preserve reference to the original paths
		const processChildren = (parent: DocumentTreeNode, parentOldPath: string, parentNewPath: string) => {
			parent.children.forEach(child => {
				const childOldPath = buildPath(parentOldPath, child.name, child.isFolder);
				const childNewPath = buildPath(parentNewPath, child.name, child.isFolder);
				operations.push({ oldPath: childOldPath, newPath: childNewPath });

				if (child.children.length) {
					processChildren(child, childOldPath, childNewPath);
				}
			});
		};

		if (node.children.length) {
			processChildren(node, oldBasePath, newBasePath);
		}

		return operations;
	}

	// renaming and deleting are similar, so this is to reuse code
	changeFile(file: DocumentTreeNode, isRename = false) {
		let operations;

		const filePartsArray = file.name.split('.')
		const extension = (filePartsArray.length > 1 && !file.isFolder) ? filePartsArray.pop() : null;
		const fileName = filePartsArray.join('.');


		if (isRename) {
			let newNameString = window.prompt('Enter new name:', `${fileName}${extension ? '.' + extension : ''}`);

			// Exit if a no length, all spaces name is provided, or modal is cancelled
			if (!newNameString || newNameString.trim().length === 0){
				return;
			}

			// always append the previous extension if one was present, not re-entered, and the file is not a folder
			if(extension && newNameString.split('.').pop() !== extension){
				newNameString += '.' + extension;
			}

			operations = this.generateChangeList(file, this.getCurrentPathString(), newNameString);
		} else {
			if (!confirm(`Delete ${file.name} permanently?`)){
				return; // Exit on cancel
			}
			operations = this.generateChangeList(file, this.getCurrentPathString());
		}
		// progress bar
		this.isProgressVisible = true;
		this.completedOperations = 0;
		this.totalOperations = operations.length;

		const promises = operations.map(operation => {
			if (isRename) {
				return this.docs.moveFile(this.rootId, operation.oldPath, operation.newPath!, this.rootType)
					.then(() => { this.completedOperations++; });
			} else {
				return this.docs.deleteFile(this.rootId, operation.oldPath, this.rootType)
					.then(() => { this.completedOperations++; });
			}
		});

		Promise.all(promises).then(() => {
			this.isProgressVisible = false;
			this.refresh.emit(this._curPath);
		}).catch(error => {
			console.error(`Error during file ${isRename ? 'renaming' : 'deletion'}:`, error);
			this.isProgressVisible = false;
		});
	}

	moveFileToFolder(folder?: DocumentTreeNode) {
		const path = [...this.breadcrumbs.map(c => c.name)];
		path.shift();
		const filename = [...path, this.fileToMove?.name].join('/');
		if (folder) {
			path.push(folder.name);
		} else {
			path.pop();
		}
		const newFilename = [...path, this.fileToMove?.name].join('/');
		this.docs.moveFile(this.rootId, filename, newFilename, this.rootType)
			.then(() => this.refresh.emit(this._curPath))
	}

	sortByFolderThenName(node) {
		const sortedChildren = [...node.children].sort((a, b) => {
			// Sort by isFolder first
			if (a.isFolder && !b.isFolder) {
				return -1;
			}
			if (!a.isFolder && b.isFolder) {
				return 1;
			}

			return a.name.localeCompare(b.name);
		});

		// Recursively sort each child node that is a folder
		sortedChildren.forEach(child => {
			if (child.isFolder) {
				child = this.sortByFolderThenName(child);
			}
		});

		return { ...node, children: sortedChildren };
	}
}