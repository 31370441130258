import { Injectable } from '@angular/core';
import { Organization, UserToOrganization } from '@models/users.model';
import { BehaviorSubject } from 'rxjs';

// service to get orgs and manage the active organization
@Injectable({
	providedIn: 'root'
})
export class OrgService {
	private _orgs: Organization[] = [];

	constructor() {}

	activeOrgSubject = new BehaviorSubject<string | null>(null);
	get activeOrgId(): string | null {
		return this.activeOrgSubject.getValue();
	}

	get activeOrg(): Organization | undefined {
		return this.orgs?.find(o => o.id === this.activeOrgId);
	}

	get orgs(): Organization[] {
		return this._orgs;
	}

	// relies on an active user profile
	updateOrgs(utos: UserToOrganization[]) {
		this._orgs = [];
		utos.forEach(uto => {
			this._orgs.push(uto.organization);
			if (uto.isDefault && this.activeOrgId === null) {
				this.setActiveOrg(uto.organization.id);
			}
		});

		// if no default, set to first org
		if (this.activeOrgId === null) {
			this.setActiveOrg(this._orgs[0].id);
		}
	}

	setActiveOrg(orgId: string) {
		this.activeOrgSubject.next(orgId);
	}
}
