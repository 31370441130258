<div class="flex grow items-center justify-center bg-cover" [ngStyle]="{
	'background-image': 'url(/assets/images/fa-bg-pattern.png)' 
}">
	<div class="w-1/2 text-center">
		<h1 class="text-fa-safety-orange text-4xl">Welcome to FarmAdvisor</h1>
		<button class="btn text-white mt-4 hover:border-fa-safety-orange hover:text-fa-safety-orange"
				(click)="auth.loginWithRedirect()"
				*ngIf="(!(auth.isLoading$ | async) && !(auth.isAuthenticated$ | async)) else updates">
			Sign In
		</button>
		<ng-template #updates>
			<div class="bg-fa-cream border-fa-dark-gray rounded-xl justify-items-center p-6 mt-4">
				<h1>What's new?</h1>
				<ul class="list-inside list-disc mt-3">
					<li>Added Bug/Feature toggle on Support Requests</li>
					<li>Added Visual Instructions on Fields in Agronomy Editor</li>
					<li>Approvers can now Reset Denied POs</li>
					<li>Added Character Limit to Support Ticket Form</li>
					<li>Added Notes to View PO</li>
					<li>Removed Disabled Properties from PO Creation List</li>
					<li>Added "Transitioning" to Planting Farming Practice</li>
				</ul>
			</div>
		</ng-template>
	</div>
</div>