<div *ngIf="!loading; else loadingBlock">
    <div class="text-left p-5">
        <h3>Open a Support Ticket</h3>
    </div>
    <div class="p-4">
        <form [formGroup]="ticketForm">
            <h4>Your Contact Email</h4>
            <p>{{ userData?.email }}</p>
            <div class="mt-3">
                <h4>Subject:<span class="text-danger">*</span></h4>
                <input class="input p-1 ps-2" formControlName="ticketSubject">
            </div>
            <div class="mt-3 flex items-center">
                <h4>Suggested Priority:</h4>
                <select class="select ml-2" formControlName="priority">
                    <option *ngFor="let priority of priorities | keyvalue:keepOrder" [value]="priority.value">
                        {{ priority.key }}
                      </option>
                </select>
            </div>
            <div class="mt-3">
                <h4>Please Describe the Issue in Detail:<span class="text-danger">*</span></h4>
                <textarea class="w-full input" rows="10" formControlName="ticketContents"></textarea>
            </div>
            <div class="mt-3">
                <h4>Attachments:</h4>
                <span class="text-danger" *ngIf="overSizeLimit()">Total size of attachments must be less than
                    {{MB_LIMIT}}MB</span>
                <div class="mt-2">
                    <input type="file" id="fileInput" style="display: none;" formControlName="attachment"
                    (change)="reactToAttachment($event)" />
                    <label for="fileInput" class="btn">Choose File</label>
                </div>
            </div>
            <ul>
                <li class="mt-2" *ngFor="let attachment of attachments; let i = index">
                    {{ attachment.name }} - {{ attachment.size / 1048576 | number : '1.2-2' }} MB
                    <fa-icon [icon]="trash" class="cursor-pointer text-danger ms-4" (click)="removeAttachment(i)"></fa-icon>
                </li>
            </ul>
        </form>
    </div>
    <div *ngIf="errMessages.length > 0">
        <ul class="ps-5 pe-5">
            <li *ngFor="let error of errMessages" class="text-danger">{{error}}</li>
        </ul>
    </div>
    <div class="p-5 text-end">
        <button type="button" class="btn btn-success px-5" (click)="onSubmit()">
            Submit
        </button>
    </div>
</div>

<ng-template #loadingBlock>
	<app-loader></app-loader>
</ng-template>