import { gql } from "@apollo/client/core";

export const UserProfileQuery = gql`
{
	profile: myUserContext {
		chatToken
		user {
			id
			firstName
			lastName
			fullName
			emailAddress
			uto: userToOrganizations (orderBy: ORGANIZATION_BY_ORGANIZATION_ID__NAME_ASC) {
				nodes {
					organization {
						id
						oldId
						name
						rootOrganization {
							id
							oldId
						}
					}
					isDefault
					isAdmin
					isGuest
					canManageVendors
					betaFeatures
				}
			}
			upa: userToPropertyActions {
				nodes {
					propertyId
					property {
						name
						organization {
						    name
						}
					}
					actionId
					action {
						id
						action
						module {
							module
						}
					}
				}
			}
		}
	}
}
`;

export const OrgUsersQuery = gql`query GetOrgUsers($orgid: String!) {
	organization(id: $orgid) {
		userToOrganizations {
			nodes {
				user {
					id
				}
			}
		}
  	}
}`;
